<template>
    <div class="ktp-info-wrapper" v-loading="loadingService">
        <div @click="$router.push({ name: 'ktp-index' })" class="btn-back-ktp">
            <div class="btn-back-arrow"></div>
        </div>
        <div class="ktp-info-header" v-if="!loadingService">
            <div class="subject-title">{{ ktp.name }}</div>
            <div class="subject-sub-title">{{ $t('profile.ktp-page.topic-2') }} {{ ktp.calendar_name }}</div>
            <div class="subject-sub-title">{{ $t('profile.subject.thema-title') }} {{ ktp.topic_name }}</div>
        </div>
        <div class="ktp-info-body" v-if="!loadingService">
            <div class="ktp-info-section">
                <b>{{ $t('profile.ktp-page.goal') }}</b> {{ ktp.lesson_purpose }}
            </div>
            <div class="ktp-info-section">
                <b>{{ $t('profile.ktp-page.tasks') }} </b>{{ ktp.lesson_tasks }}
            </div>
            <div class="ktp-info-section">
                <b>{{ $t('profile.home-works.full-desc') }} </b>
                <div class="ktp-info-section-inside" v-html="ktp.lesson_content"></div>
            </div>
            <div class="ktp-info-section files-section" v-if="ktp.files.length">
                <b>{{ $t('profile.subject.lesson-materials') }} </b>
                <div class="ktp-file-download-wrapper">
                    <a :href="apiRoot + '/storage/' + item.file" target="_blank" class="file-download" v-for="item in ktp.files">
                        <span>{{ item.file.substring(6) }}</span>
                        <div class="file-download-img"></div>
                    </a>
                </div>
                <div class="or-ktp">{{ $t('profile.subject.or') }}</div>
                <div class="btn-download">{{ $t('profile.subject.download') }}</div>
            </div>
            <div class="ktp-info-section files-section" v-show="false">
                <b>{{ $t('profile.ktp-page.tasks-2') }} </b>
                <div class="ktp-file-download-wrapper">
                    <div class="file-download">de-u.docx
                        <div class="file-download-img"></div>
                    </div>
                    <div class="file-download">de-u.docx
                        <div class="file-download-img"></div>
                    </div>
                    <div class="file-download">de-u.docx
                        <div class="file-download-img"></div>
                    </div>
                    <div class="file-download">de-u.docx
                        <div class="file-download-img"></div>
                    </div>
                    <div class="file-download">de-u.docx
                        <div class="file-download-img"></div>
                    </div>
                </div>
                <div class="or-ktp">{{ $t('profile.subject.or') }}</div>
                <div class="btn-download">{{ $t('profile.subject.download') }}</div>
            </div>
        </div>
        <div class="footer-wave"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            loadingService: false,
            ktp: {
                files: []
            },
            apiRoot: window.API_ROOT
        }
    },
    mounted() {
        this.loadingService = true;
        setTimeout(() => {
            this.loadingService = false;
        }, 5000);
        let id = this.$route.params.id
        this.$http.get(`${window.API_ROOT}/api/teacher/topic-content/${id}`)
            .then((res) => {
                this.ktp = res.body.data
                this.loadingService = false
            })
            .catch(() => {
                this.loadingService = false
            })
    }
}
</script>

<style lang="less" scoped>
.footer-wave{
  margin-top: 2rem;
  height: 90px;
  width:100%;
  background-image: url("/images/footer-wave-ktp.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
.or-ktp{
  width:max-content;
  margin: 15px auto 20px;
}
.btn-download {
  width: 146px;
  margin: 0 auto;
  user-select: none;
  background: #473F95;
  border-radius: 5px;
  cursor:pointer;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
}
.file-download-img{
  background-image: url("/images/file-download.svg");
  margin: 12px auto 0;
  width: 19px;
  height: 31px;
  background-size: contain;
}
.file-download:hover{
    text-decoration: none;
    color: #7773a2;
}
.file-download{
  user-select: none;
  color: #473F95;
  cursor: pointer;
  margin: 0 15px 0 0;
  border-radius: 4px;
  padding: 5px;
  text-overflow: ellipsis;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  width:120px;
  height:75px;
  border: 1px dashed #473F95;
}
.file-download span{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ktp-file-download-wrapper{
  margin-top: 1rem;
  display:flex
}
.ktp-info-section-inside{
    margin-top: 15px;
    background: #EEEAFB;
    border-radius: 4px;
    padding: 25px 25px 10px;
}
.ktp-info-section{
  margin-top: 32px;
  b{font-weight: bold}
}
.ktp-info-section.files-section {
    max-width: 660px;
}
.ktp-info-body{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 21px;
  color: #473F95;
  padding: 0 38px;
}
.btn-back-ktp{
  cursor: pointer;
  user-select: none;
  height:58px;
  width:58px;
  background: #EEEAFB;
  border-radius: 5px 0;
  display:flex;
  justify-content:center;
}
.btn-back-arrow{
  margin: auto;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("/images/arrow-left.svg");
}
.subject-title{
  font-size: 24px;
  line-height: 28px;
}
.ktp-info-header{
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-style: normal;
  text-align: center;
  color: #473F95;
  margin:0 auto;
  width:max-content;
}
.ktp-info-header div{
    margin-bottom: 5px;
}
.ktp-info-wrapper{
  max-width: 1122px;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}
.btn-watch {
  user-select: none;
  background: #473F95;
  border-radius: 5px;
  cursor:pointer;
  color: white;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
}

table {
  width: 100%;
  margin-top: 3rem;
}

thead {
  background-color: white;
  color: #D23168;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  td {
    padding: 22px 20px 10px;
  }

}

tbody {
  background-color: white;
  color: #473F95;

  td {
    padding: 16px 20px 10px;
  }
}

.ktp-wrapper {
  //margin: 0 auto
  max-width: 100%;
}

.subject-select {
  width: 260px;
  height: 36px;
  margin-right: 10px;
}

.class-select {
  width: 154px;
  height: 36px;
}
@media (max-width: 991px)  {
    .subject-title {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }
    .subject-sub-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
    }
    .ktp-info-section {
        margin-top: 26px;
        font-size: 14px;
        line-height: 16px;
    }
    .ktp-info-body {
        padding: 0 12px;
    }
    .ktp-info-header {
        margin-top: 14px;
    }
    .footer-wave {
        background-image: url("/images/profile/wave-hw-modal.svg");
        background-size: 100% 80%;
        background-repeat: no-repeat;
        background-position: bottom;
        margin-top: 0;
    }
    .file-download  {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: 106px;
        height: 31px;
    }
    .file-download span {
        font-size: 13px;
    }
    .file-download-img {
        width: 40px;
        height: 22px;
        background-repeat: no-repeat;
        margin-top: 6px;
        margin-right: 8px;
    }
    .ktp-info-section-inside {
        padding: 16px;
    }
    .or-ktp {
        margin: 26px auto 10px;
    }
}

</style>